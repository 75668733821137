'use client';
import { useRef, useState } from 'react';
import { BiImageAdd } from 'react-icons/bi';
import classes from './SendPicture.module.css';

const SendPicture = (props) => {
	const { setSelectedImage, text } = props;
	const hiddenFileInput = useRef(null);

	const [previewImage, setPreviewImage] = useState(null);
	const [previewVideo, setPreviewVideo] = useState(null);
	const [showPreview, setShowPreview] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const handleClick = (e) => {
		hiddenFileInput.current.click();
	};

	const onFileChange = (e) => {
		setPreviewImage(null);
		setPreviewVideo(null);
		const file = e.target.files[0];

		if (file) {
			const imageURL = URL.createObjectURL(file);

			if (file.type?.toLowerCase()?.includes('image')) {
				setPreviewImage(imageURL);
			} else if (file.type?.toLowerCase()?.includes('video')) {
				setPreviewVideo(imageURL);
			}

			setSelectedFile(file);
			setShowPreview(true); // show the preview window
		}
		e.target.value = null; // reset the value
	};

	const handleSend = () => {
		setShowPreview(false);
		setSelectedImage(selectedFile);
	};

	const handleCancel = () => {
		setShowPreview(false);
		setPreviewImage(null);
		setPreviewVideo(null);
	};

	return (
		<>
			<input
				className={classes.inputDivInvisible}
				ref={hiddenFileInput}
				type="file"
				onChange={(e) => onFileChange(e)}
				accept={props.video ? 'image/*, video/*' : 'image/*'}
				// accept=".png, .jpg, .jpeg, .mp4, .MOV, .webm, .gif"
			/>
			<BiImageAdd
				size={30}
				className={`${classes.imageUpload} ${props.className}`}
				onClick={handleClick}
			/>
			{showPreview && (
				<div className={classes.previewWindow}>
					{previewImage ? (
						<img
							src={previewImage}
							alt="Selected preview"
							className={classes.previewImage}
						/>
					) : previewVideo ? (
						<video
							src={previewVideo}
							controls
							alt="Selected preview"
							className={classes.previewImage}
						/>
					) : (
						''
					)}
					<div className={classes.buttonContainer}>
						<button onClick={handleCancel}>Cancel</button>
						<button
							type="submit"
							className={classes.sendButton}
							onClick={handleSend}
						>
							{text ? text : 'Send'}
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default SendPicture;
