'use client';
import Cookies from 'js-cookie';
import { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from './';

// Create the cart context with default values
const NotificationsContext = createContext({
	loading: false,
	notifications: [],
	count: 0,
	notPreviewed: 0,
	maxed: false,

	LOAD_NOTIFICATIONS: () => {},
	PREVIEW_NOTIFICATIONS: () => {},
	REMOVE_NOTIFICATION: () => {},
});

// Create a provider component for the cart context
export const NotificationsProvider = ({ children }) => {
	const { auth, setAuth } = useAuthContext();

	const [notifications, setNotifications] = useState({
		loading: false,
		notifications: [],
		count: 0,
		notPreviewed: 0,
		maxed: false,
	});

	const LOAD_NOTIFICATIONS = async (loadMore) => {
		const token = Cookies.get('token');

		if (notifications.loading) {
			return;
		}

		if (!token) return console.error('Token is missing');

		axios.defaults.headers.common['x-auth-token'] = token;

		setNotifications((prevState) => ({
			...prevState,
			loading: true,
		}));

		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/auth/get-nots`,
				{
					skip: loadMore ? notifications.count : 0,
				},
				{
					headers: {
						'x-auth-token': token,
					},
				}
			)
			.then(async (res) => {
				const { notifications, notPreviewed, maxed } = res.data;

				setNotifications((prevState) => ({
					notifications,
					count: notifications.length,
					maxed,
					notPreviewed,
					loading: false,
				}));
			})
			.catch(async (error) => {
				setNotifications((prevState) => ({
					...prevState,
					loading: false,
				}));
			});
	};

	const PREVIEW_NOTIFICATIONS = async (loadMore) => {
		setNotifications((prevState) => ({
			...prevState,
			notPreviewed: 0,
		}));

		// return;
		await axios
			.get(`${process.env.NEXT_PUBLIC_SERVER_API}/api/auth/preview-nots`, {
				headers: {
					'x-auth-token': Cookies.get('token'),
				},
			})
			.then(async (res) => {})
			.catch(async (error) => {});
	};

	const REMOVE_NOTIFICATION = async (notId) => {
		setNotifications((prevState) => ({
			...prevState,
			loading: true,
		}));

		try {
			let newNotifications = JSON.parse(
				JSON.stringify([...notifications.notifications])
			);

			const notIndex = newNotifications.findIndex(
				(i) => i._id.toString() === notId.toString()
			);

			newNotifications[notIndex].seen = true;

			setNotifications((prevState) => ({
				notifications: newNotifications.sort((a, b) =>
					a.date < b.date ? 1 : -1
				),
				maxed: notifications.maxed,
				notPreviewed: notifications.notPreviewed,
				loading: false,
				count: notifications.length,
			}));

			await axios
				.get(
					`${process.env.NEXT_PUBLIC_SERVER_API}/api/auth/remove-notification/${notId}`,
					{
						headers: {
							'x-auth-token': Cookies.get('token'),
						},
					}
				)
				.then((res) => {})
				.catch((err) => {
					console.log(err);
				});
		} catch (err) {
			console.log(err);
			setNotifications((prevState) => ({
				...prevState,
				loading: false,
			}));
		}
	};

	useEffect(() => {
		if (auth.isAuthenticated) {
			LOAD_NOTIFICATIONS();
		}
	}, [auth.isAuthenticated]);

	return (
		<NotificationsContext.Provider
			value={{
				notifications,
				LOAD_NOTIFICATIONS,
				PREVIEW_NOTIFICATIONS,
				REMOVE_NOTIFICATION,
			}}
		>
			{children}
		</NotificationsContext.Provider>
	);
};

// Custom hook to use the cart context
export const useNotificationsContext = () => {
	return useContext(NotificationsContext);
};
