import axios from "axios";
import { motion } from "framer-motion";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { BsDot } from "react-icons/bs";
import { FiDollarSign } from "react-icons/fi";
import { MdOutlineMarkChatUnread, MdOutlineSell } from "react-icons/md";
import { TbHeartHandshake } from "react-icons/tb";
import Link from "next/link";
import classes from "../Navbar.module.css";
import { useNotificationsContext } from "@/context/notifications";
import { useAuthContext } from "@/context";
import { useAlertContext } from "@/context/alert";

const NotificationsModal = (props) => {
  const {
    refNotifications,
    setShowNotifications,
    counterOffer,
    removeNotification,
    lastNot,
    auth,
  } = props;

  const { SET_ALERT } = useAlertContext();

  moment.locale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "seconds ago",
      ss: "%ss",
      m: "minutes ago",
      mm: "%dm",
      h: "an hour",
      hh: "%dh",
      d: "a day",
      dd: "%dd",
      M: "%dM",
      MM: "%dM",
      y: "a year",
      yy: "%dY",
    },
  });

  const { notifications } = useNotificationsContext();

  const { LOAD_USER } = useAuthContext();

  const handleReviewNotification = async (notification, e) => {
    removeNotification(notification._id, e);

    await axios
      .post(`${process.env.NEXT_PUBLIC_SERVER_API}/api/auth/review-not`, {
        notification,
      })
      .then((res) => {
        LOAD_USER();
      })
      .catch((err) => {
        SET_ALERT({
          msg: "You have already review this order",
          type: "success",
        });
      });
  };

  // const findDisplayTitle = (listing) => {
  //   if (listing.velouAttributes?.generatedContent) {
  //     const title = listing.velouAttributes.generatedContent.find
  //       ? listing.velouAttributes.generatedContent.find(
  //           ({ fieldName }) => fieldName === "productTitle"
  //         )
  //       : null;
  //     if (title) {
  //       return title.values["en-us"];
  //     }
  //   }
  //   return listing.title;
  // };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: "100px" }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 0.4,
          type: "spring",
          stiffness: 400,
          damping: 40,
        }}
        className={classes.bellNavMenu}
        ref={refNotifications}
        onClick={() => setShowNotifications(false)}
      >
        <div className={classes.notificationHeader}>
          <p className={classes.cartHeader}>Notifications</p>
        </div>
        <div className={classes.overflow}>
          {[...notifications.notifications]
            .sort((a, b) => (a.date < b.date ? 1 : -1))
            .map((notification, index) =>
              notification?.listing ||
              notification.items.length > 0 ||
              notification?.listings[0]?.listing ? (
                <div key={index}>
                  {notification.type === "offer" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={() => counterOffer(notification)}
                        key={notification._id}
                        href={`/product/${
                          notification.listing.kashewId
                        }/${notification.listing?.title
                          ?.toLowerCase()
                          .replace(/[_\W]+/g, "-")
                          .replace(/^-+/, "")
                          .replace(/-+$/, "")}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <img
                          src={
                            notification.listing &&
                            notification.listing.images &&
                            notification.listing.images.length > 0
                              ? notification.listing.images[0]
                              : notification.listings &&
                                notification.listings[0] &&
                                notification.listings[0].listing &&
                                notification.listings[0].listing.images[0]
                          }
                          className={classes.listingImage}
                          alt={
                            notification.listing
                              ? notification.listing.title
                              : "notification"
                          }
                        />
                        <div className={classes.sellIcon}>
                          <MdOutlineSell className={classes.icon1} />
                        </div>
                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            <b className={classes.boldText}>
                              {notification.sender.firstName}
                            </b>{" "}
                            wants to purchase your{" "}
                            <b className={classes.boldText}>
                              {notification.listing.title}
                            </b>
                          </h4>

                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "offer-declined" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/product/${
                          notification.listing.kashewId
                        }/${notification.listing?.title
                          ?.toLowerCase()
                          .replace(/[_\W]+/g, "-")
                          .replace(/^-+/, "")
                          .replace(/-+$/, "")}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <img
                          src={
                            notification.listing &&
                            notification.listing.images &&
                            notification.listing.images.length > 0
                              ? notification.listing.images[0]
                              : notification.listings &&
                                notification.listings[0] &&
                                notification.listings[0].listing &&
                                notification.listings[0].listing.images[0]
                          }
                          className={classes.listingImage}
                          alt={
                            notification.listing
                              ? notification.listing.title
                              : "notification"
                          }
                        />
                        <div className={classes.sellIconDeleted}>
                          <MdOutlineSell className={classes.icon1} />
                        </div>
                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            <b className={classes.boldText}>
                              {notification.sender.firstName}
                            </b>{" "}
                            declined your offer of{" "}
                            <b className={classes.boldText}>
                              ${notification.amount}
                            </b>
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "counter-offer" ? (
                    <div className={classes.notify}>
                      <div
                        className={classes.notificationNavMenuItem}
                        onClick={() => counterOffer(notification)}
                      >
                        <img
                          src={
                            notification.listing &&
                            notification.listing.images &&
                            notification.listing.images.length > 0
                              ? notification.listing.images[0]
                              : notification.listings &&
                                notification.listings[0] &&
                                notification.listings[0].listing &&
                                notification.listings[0].listing.images[0]
                          }
                          className={classes.listingImage}
                          alt={
                            notification.listing
                              ? notification.listing.title
                              : "notification"
                          }
                        />
                        <div className={classes.sellIcon}>
                          <FiDollarSign className={classes.icon1} />
                        </div>
                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            <b className={classes.boldText}>The seller</b> is
                            lowering the price to{" "}
                            <b className={classes.boldText}>
                              ${notification.amount}
                            </b>
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </div>
                    </div>
                  ) : notification.type === "offer-accepted" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/purchase?&tab=purchase&selection=openPurchase&order=${notification.order}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <img
                          src={
                            notification.listing &&
                            notification.listing.images &&
                            notification.listing.images.length > 0
                              ? notification.listing.images[0]
                              : notification.listings &&
                                notification.listings[0] &&
                                notification.listings[0].listing &&
                                notification.listings[0].listing.images[0]
                          }
                          className={classes.listingImage}
                          alt={
                            notification.listing
                              ? notification.listing.title
                              : "notification"
                          }
                        />
                        <div className={classes.sellIcon}>
                          <MdOutlineSell className={classes.icon1} />
                        </div>
                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            {notification.text}
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "new-offer" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/sales?&tab=offer&selection=openOffers&order=${notification.order}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <img
                          src={
                            notification.listing &&
                            notification.listing.images &&
                            notification.listing.images.length > 0
                              ? notification.listing.images[0]
                              : notification.listings &&
                                notification.listings[0] &&
                                notification.listings[0].listing &&
                                notification.listings[0].listing.images[0]
                          }
                          className={classes.listingImage}
                          alt={
                            notification.listing
                              ? notification.listing.title
                              : "notification"
                          }
                        />
                        <div className={classes.sellIcon}>
                          <MdOutlineSell className={classes.icon1} />
                        </div>
                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            {notification.text}
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "listing-request" ? (
                    <>
                      <div className={classes.notify}>
                        <Link
                          onClick={(e) =>
                            removeNotification(notification._id, e)
                          }
                          key={notification._id}
                          href={
                            notification.listing
                              ? `/product/${
                                  notification.listing.kashewId
                                }/${notification.listing?.title
                                  ?.toLowerCase()
                                  .replace(/[_\W]+/g, "-")
                                  .replace(/^-+/, "")
                                  .replace(/-+$/, "")}#askSeller`
                              : ""
                          }
                          className={classes.notificationNavMenuItem}
                        >
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />

                          <div className={classes.message}>
                            <h4
                              className={
                                notification.seen
                                  ? classes.notificationText
                                  : classes.notificationTextNotSeen
                              }
                            >
                              <b className={classes.boldText}>New inquiry: </b>
                              {notification.text}
                            </h4>
                            <h6 className={classes.sent}>
                              <BsDot className={classes.dot} />{" "}
                              {moment(notification.date).fromNow()}
                            </h6>
                          </div>
                          {notification.seen ? (
                            <></>
                          ) : (
                            <div className={classes.chatNotification} />
                          )}
                        </Link>
                      </div>
                    </>
                  ) : notification.type === "listing-updated" ? (
                    <>
                      <div className={classes.notify}>
                        <Link
                          onClick={(e) =>
                            removeNotification(notification._id, e)
                          }
                          key={notification._id}
                          href={`/product/${
                            notification.listing.kashewId
                          }/${notification.listing?.title
                            ?.toLowerCase()
                            .replace(/[_\W]+/g, "-")
                            .replace(/^-+/, "")
                            .replace(/-+$/, "")}`}
                          className={classes.notificationNavMenuItem}
                        >
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />
                          <div className={classes.sellIcon}>
                            <FiDollarSign className={classes.icon1} />
                          </div>
                          <div className={classes.message}>
                            <h4
                              className={
                                notification.seen
                                  ? classes.notificationText
                                  : classes.notificationTextNotSeen
                              }
                            >
                              <b className={classes.boldText}>
                                {" "}
                                {notification.listing.title}
                              </b>{" "}
                              has been updated by its author
                            </h4>
                            <h6 className={classes.sent}>
                              <BsDot className={classes.dot} />{" "}
                              {moment(notification.date).fromNow()}
                            </h6>
                          </div>
                          {notification.seen ? (
                            <></>
                          ) : (
                            <div className={classes.chatNotification} />
                          )}
                        </Link>
                      </div>
                    </>
                  ) : notification.type === "delivery-upgrade-accepted" ? (
                    <>
                      <div className={classes.notify}>
                        <Link
                          onClick={(e) =>
                            removeNotification(notification._id, e)
                          }
                          key={notification._id}
                          href={`/purchases?&tab=purchases&selection=openPurchases&order=${notification.order}`}
                          className={classes.notificationNavMenuItem}
                        >
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />
                          <div className={classes.sellIcon}>
                            <FiDollarSign className={classes.icon1} />
                          </div>
                          <div className={classes.message}>
                            <h4
                              className={
                                notification.seen
                                  ? classes.notificationText
                                  : classes.notificationTextNotSeen
                              }
                            >
                              {notification.text}
                            </h4>
                            <h6 className={classes.sent}>
                              <BsDot className={classes.dot} />{" "}
                              {moment(notification.date).fromNow()}
                            </h6>
                          </div>
                          {notification.seen ? (
                            <></>
                          ) : (
                            <div className={classes.chatNotification} />
                          )}
                        </Link>
                      </div>
                    </>
                  ) : notification.type === "request-reply" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/product/${
                          notification.listing.kashewId
                        }/${notification.listing?.title
                          ?.toLowerCase()
                          .replace(/[_\W]+/g, "-")
                          .replace(/^-+/, "")
                          .replace(/-+$/, "")}#askSeller`}
                        className={classes.notificationNavMenuItem}
                      >
                        <img
                          src={
                            notification.listing &&
                            notification.listing.images &&
                            notification.listing.images.length > 0
                              ? notification.listing.images[0]
                              : notification.listings &&
                                notification.listings[0] &&
                                notification.listings[0].listing &&
                                notification.listings[0].listing.images[0]
                          }
                          className={classes.listingImage}
                          alt={
                            notification.listing
                              ? notification.listing.title
                              : "notification"
                          }
                        />

                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            You have received a reply
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "new-chat-message" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`?chat=${notification.chat}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <img
                          src={
                            notification.listing &&
                            notification.listing.images &&
                            notification.listing.images.length > 0
                              ? notification.listing.images[0]
                              : notification.listings &&
                                notification.listings[0] &&
                                notification.listings[0].listing &&
                                notification.listings[0].listing.images[0]
                          }
                          className={classes.listingImage}
                          alt={
                            notification.listing
                              ? notification.listing.title
                              : "notification"
                          }
                        />
                        <div className={classes.inquiryIcon}>
                          <MdOutlineMarkChatUnread className={classes.icon1} />
                        </div>
                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            <b className={classes.boldText}>
                              {" "}
                              {notification.sender.firstName}
                            </b>{" "}
                            has sent you a message
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "new-order" ? (
                    <>
                      <div className={classes.notify}>
                        <Link
                          onClick={(e) =>
                            removeNotification(notification._id, e)
                          }
                          key={notification._id}
                          href={`/sales?&tab=sales&selection=openSales&order=${notification.order}`}
                          className={classes.notificationNavMenuItem}
                        >
                          <div className={classes.imageWrapper}>
                            <img
                              src={
                                notification.listing &&
                                notification.listing.images &&
                                notification.listing.images.length > 0
                                  ? notification.listing.images[0]
                                  : notification.listings &&
                                    notification.listings[0] &&
                                    notification.listings[0].listing &&
                                    notification.listings[0].listing.images[0]
                              }
                              className={classes.listingImage}
                              alt={
                                notification.listing
                                  ? notification.listing.title
                                  : "notification"
                              }
                            />
                            {notification.listings.length > 1 && (
                              <div className={classes.plusItems}>
                                +{notification.listings.length - 1}
                              </div>
                            )}
                          </div>

                          <div className={classes.sellIcon}>
                            <MdOutlineSell className={classes.icon1} />
                          </div>

                          <div className={classes.message}>
                            <h4
                              className={
                                notification.seen
                                  ? classes.notificationText
                                  : classes.notificationTextNotSeen
                              }
                            >
                              <b className={classes.boldText}>
                                {" "}
                                {notification.sender.firstName}
                              </b>{" "}
                              ordered your{" "}
                              <b className={classes.boldText}>
                                {notification.listing
                                  ? notification.listing.title
                                  : notification.listings[0].listing.title}{" "}
                              </b>
                              {notification.listings.length > 1 ? (
                                <>
                                  and{" "}
                                  <b className={classes.boldText}>
                                    {" "}
                                    {notification.listings.length - 1} more
                                    items
                                  </b>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </h4>
                            <h6 className={classes.sent}>
                              <BsDot className={classes.dot} />{" "}
                              {moment(notification.date).fromNow()}
                            </h6>
                          </div>
                          {notification.seen ? (
                            <></>
                          ) : (
                            <div className={classes.chatNotification} />
                          )}
                        </Link>
                      </div>
                    </>
                  ) : notification.type === "cancellation-request" ? (
                    <>
                      <div className={classes.notify}>
                        <Link
                          onClick={(e) =>
                            removeNotification(notification._id, e)
                          }
                          key={notification._id}
                          href={`/sales?&tab=sales&selection=openSales&order=${notification.order}`}
                          className={classes.notificationNavMenuItem}
                        >
                          <div className={classes.imageWrapper}>
                            <img
                              src={
                                notification.listing &&
                                notification.listing.images &&
                                notification.listing.images.length > 0
                                  ? notification.listing.images[0]
                                  : notification.listings &&
                                    notification.listings[0] &&
                                    notification.listings[0].listing &&
                                    notification.listings[0].listing.images[0]
                              }
                              className={classes.listingImage}
                              alt={
                                notification.listing
                                  ? notification.listing.title
                                  : "notification"
                              }
                            />
                            {notification.listings.length > 1 && (
                              <div className={classes.plusItems}>
                                +{notification.listings.length - 1}
                              </div>
                            )}
                          </div>

                          <div className={classes.sellIcon}>
                            <MdOutlineSell className={classes.icon1} />
                          </div>

                          <div className={classes.message}>
                            <h4
                              className={
                                notification.seen
                                  ? classes.notificationText
                                  : classes.notificationTextNotSeen
                              }
                            >
                              <b className={classes.boldText}>
                                {notification.sender.firstName}
                              </b>{" "}
                              has requested to delete their order
                            </h4>
                            <h6 className={classes.sent}>
                              <BsDot className={classes.dot} />{" "}
                              {moment(notification.date).fromNow()}
                            </h6>
                          </div>
                          {notification.seen ? (
                            <></>
                          ) : (
                            <div className={classes.chatNotification} />
                          )}
                        </Link>
                      </div>
                    </>
                  ) : notification.type === "order-accepted" ? (
                    <>
                      <div className={classes.notify}>
                        <Link
                          onClick={(e) =>
                            removeNotification(notification._id, e)
                          }
                          key={notification._id}
                          href={`/purchase?&tab=purchase&selection=openPurchase&order=${notification.order}`}
                          className={classes.notificationNavMenuItem}
                        >
                          <div className={classes.imageWrapper}>
                            <img
                              src={
                                notification.listing &&
                                notification.listing.images &&
                                notification.listing.images.length > 0
                                  ? notification.listing.images[0]
                                  : notification.listings &&
                                    notification.listings[0] &&
                                    notification.listings[0].listing &&
                                    notification.listings[0].listing.images[0]
                              }
                              className={classes.listingImage}
                              alt={
                                notification.listing
                                  ? notification.listing.title
                                  : "notification"
                              }
                            />
                            {notification.listings.length > 1 && (
                              <div className={classes.plusItems}>
                                +{notification.listings.length - 1}
                              </div>
                            )}
                          </div>
                          <div className={classes.sellIcon}>
                            <MdOutlineSell className={classes.icon1} />
                          </div>

                          <div className={classes.message}>
                            <h4
                              className={
                                notification.seen
                                  ? classes.notificationText
                                  : classes.notificationTextNotSeen
                              }
                            >
                              {notification.sender.toString() ===
                              auth.user._id.toString() ? (
                                <>Your order is confirmed!</>
                              ) : (
                                <>
                                  <b className={classes.boldText}>
                                    {notification.sender.firstName}
                                  </b>{" "}
                                  has accepted your order!{" "}
                                </>
                              )}
                            </h4>
                            <h6 className={classes.sent}>
                              <BsDot className={classes.dot} />{" "}
                              {moment(notification.date).fromNow()}
                            </h6>
                          </div>
                          {notification.seen ? (
                            <></>
                          ) : (
                            <div className={classes.chatNotification} />
                          )}
                        </Link>
                      </div>
                    </>
                  ) : notification.type === "counteroffer-accepted" ? (
                    <>
                      <div className={classes.notify}>
                        <Link
                          onClick={(e) =>
                            removeNotification(notification._id, e)
                          }
                          key={notification._id}
                          href={`/sales?&tab=sales&selection=openSales&order=${notification.order}`}
                          className={classes.notificationNavMenuItem}
                        >
                          <div className={classes.imageWrapper}>
                            <img
                              src={
                                notification.listing &&
                                notification.listing.images &&
                                notification.listing.images.length > 0
                                  ? notification.listing.images[0]
                                  : notification.listings &&
                                    notification.listings[0] &&
                                    notification.listings[0].listing &&
                                    notification.listings[0].listing.images[0]
                              }
                              className={classes.listingImage}
                              alt={
                                notification.listing
                                  ? notification.listing.title
                                  : "notification"
                              }
                            />
                            {notification.listings.length > 1 && (
                              <div className={classes.plusItems}>
                                +{notification.listings.length - 1}
                              </div>
                            )}
                          </div>
                          <div className={classes.sellIcon}>
                            <MdOutlineSell className={classes.icon1} />
                          </div>

                          <div className={classes.message}>
                            <h4
                              className={
                                notification.seen
                                  ? classes.notificationText
                                  : classes.notificationTextNotSeen
                              }
                            >
                              <>
                                <b className={classes.boldText}>
                                  {notification.sender.firstName}
                                </b>{" "}
                                has accepted your counter offer!{" "}
                              </>
                            </h4>
                            <h6 className={classes.sent}>
                              <BsDot className={classes.dot} />{" "}
                              {moment(notification.date).fromNow()}
                            </h6>
                          </div>
                          {notification.seen ? (
                            <></>
                          ) : (
                            <div className={classes.chatNotification} />
                          )}
                        </Link>
                      </div>
                    </>
                  ) : notification.type === "order-deleted" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/purchases?&tab=purchases&selection=closedPurchases&order=${notification.order}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <div className={classes.imageWrapper}>
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />
                          {notification.listings.length > 1 && (
                            <div className={classes.plusItems}>
                              +{notification.listings.length - 1}
                            </div>
                          )}
                        </div>
                        <div className={classes.sellIconDeleted}>
                          <MdOutlineSell className={classes.icon1} />
                        </div>

                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            {notification.text}
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "new-timeslots" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/orders?chat=${notification.chat}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <div className={classes.imageWrapper}>
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />
                          {notification.listings.length > 1 && (
                            <div className={classes.plusItems}>
                              +{notification.listings.length - 1}
                            </div>
                          )}
                        </div>
                        <div className={classes.sellIconDeleted}>
                          <TbHeartHandshake className={classes.icon1} />
                        </div>

                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            <b className={classes.boldText}>
                              {" "}
                              {notification.sender.firstName}
                            </b>{" "}
                            has proposed new timeslots
                          </h4>
                        </div>
                        <h6 className={classes.sent}>
                          <BsDot className={classes.dot} />{" "}
                          {moment(notification.date).fromNow()}
                        </h6>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "payment-received" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/payouts`}
                        className={classes.notificationNavMenuItem}
                      >
                        <div className={classes.imageWrapper}>
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />
                          {notification.listings.length > 1 && (
                            <div className={classes.plusItems}>
                              +{notification.listings.length - 1}
                            </div>
                          )}
                        </div>
                        <div className={classes.sellIconPaid}>
                          <MdOutlineSell className={classes.icon1} />
                        </div>

                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            You just got paid!
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "order-reschedule" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/orders?chat=${notification.chat}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <div className={classes.imageWrapper}>
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />
                          {notification.listings.length > 1 && (
                            <div className={classes.plusItems}>
                              +{notification.listings.length - 1}
                            </div>
                          )}
                        </div>
                        <div className={classes.sellIconDeleted}>
                          <TbHeartHandshake className={classes.icon1} />
                        </div>

                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            <b className={classes.boldText}>
                              {" "}
                              {notification.sender.firstName}
                            </b>{" "}
                            wants to reschedule your order
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "completed-purchase" ||
                    notification.type === "completed-sale" ? (
                    <>
                      <div className={classes.notify}>
                        <div
                          onClick={(e) =>
                            handleReviewNotification(notification, e)
                          }
                          key={notification._id}
                          className={classes.notificationNavMenuItem}
                        >
                          <div className={classes.imageWrapper}>
                            <img
                              src={
                                notification.listing &&
                                notification.listing.images &&
                                notification.listing.images.length > 0
                                  ? notification.listing.images[0]
                                  : notification.listings &&
                                    notification.listings[0] &&
                                    notification.listings[0].listing &&
                                    notification.listings[0].listing.images[0]
                              }
                              className={classes.listingImage}
                              alt={
                                notification.listing
                                  ? notification.listing.title
                                  : "notification"
                              }
                            />
                            {notification.listings.length > 1 && (
                              <div className={classes.plusItems}>
                                +{notification.listings.length - 1}
                              </div>
                            )}
                          </div>
                          <div className={classes.sellIcon}>
                            <MdOutlineSell className={classes.icon1} />
                          </div>

                          <div className={classes.message}>
                            <h4
                              className={
                                notification.seen
                                  ? classes.notificationText
                                  : classes.notificationTextNotSeen
                              }
                            >
                              {notification.text}
                            </h4>
                            <h6 className={classes.sent}>
                              <BsDot className={classes.dot} />{" "}
                              {moment(notification.date).fromNow()}
                            </h6>
                          </div>
                          {notification.seen ? (
                            <></>
                          ) : (
                            <div className={classes.chatNotification} />
                          )}
                        </div>
                      </div>
                    </>
                  ) : notification.type === "counteroffer-updated" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`?chat=${notification.chat}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <div className={classes.imageWrapper}>
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />
                          {notification.listings.length > 1 && (
                            <div className={classes.plusItems}>
                              +{notification.listings.length - 1}
                            </div>
                          )}
                        </div>
                        <div className={classes.sellIcon}>
                          <MdOutlineSell className={classes.icon1} />
                        </div>

                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            <b className={classes.boldText}>The seller</b> has
                            edited their counteroffer
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "price-reduced" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/product/${
                          notification.listing.kashewId
                        }/${notification.listing?.title
                          ?.toLowerCase()
                          .replace(/[_\W]+/g, "-")
                          .replace(/^-+/, "")
                          .replace(/-+$/, "")}`}
                        className={classes.notificationNavMenuItem}
                      >
                        <div className={classes.imageWrapper}>
                          <img
                            src={
                              notification.listing &&
                              notification.listing.images &&
                              notification.listing.images.length > 0
                                ? notification.listing.images[0]
                                : notification.listings &&
                                  notification.listings[0] &&
                                  notification.listings[0].listing &&
                                  notification.listings[0].listing.images[0]
                            }
                            className={classes.listingImage}
                            alt={
                              notification.listing
                                ? notification.listing.title
                                : "notification"
                            }
                          />
                          {notification.listings.length > 1 && (
                            <div className={classes.plusItems}>
                              +{notification.listings.length - 1}
                            </div>
                          )}
                        </div>
                        <div className={classes.sellIcon}>
                          <MdOutlineSell className={classes.icon1} />
                        </div>
                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            {notification.text} for {notification.listing.title}
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : notification.type === "new-consignment-request" ? (
                    <div className={classes.notify}>
                      <Link
                        onClick={(e) => removeNotification(notification._id, e)}
                        key={notification._id}
                        href={`/seller-dashboard?tab=consignments`}
                        className={classes.notificationNavMenuItem}
                      >
                        <div className={classes.imageWrapper}>
                          <img
                            src={notification.items[0].images[0]}
                            className={classes.listingImage}
                            alt={"notification"}
                          />
                        </div>
                        <div className={classes.consignmentIcon}>
                          <TbHeartHandshake className={classes.icon1} />
                        </div>
                        <div className={classes.message}>
                          <h4
                            className={
                              notification.seen
                                ? classes.notificationText
                                : classes.notificationTextNotSeen
                            }
                          >
                            {notification.text}
                          </h4>
                          <h6 className={classes.sent}>
                            <BsDot className={classes.dot} />{" "}
                            {moment(notification.date).fromNow()}
                          </h6>
                        </div>
                        {notification.seen ? (
                          <></>
                        ) : (
                          <div className={classes.chatNotification} />
                        )}
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                ""
              )
            )}

          {/* {!notifications.loading &&
						notifications.notifications.length > 0 &&
						!notifications.maxed && (
							<div className={classes.loadMoreNots} onClick={loadMoreHandler}>
								Load more
							</div>
						)} */}

          {notifications.loading && (
            <Spinner animation="border" variant="success" />
          )}

          {!notifications.loading &&
            notifications.notifications.length === 0 && (
              <div className={classes.notificationNavMenuItem}>
                <h4 className={classes.notificationText}>
                  <>No notifications</>
                </h4>
              </div>
            )}

          <div ref={lastNot} />
        </div>
      </motion.div>
    </>
  );
};

export default NotificationsModal;
