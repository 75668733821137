'use client';

import { useEffect } from 'react';

export const useDisableBodyScroll = (open) => {
	useEffect(() => {
		if (open) {
			document.body.style.overflowY = 'hidden';
		} else {
			document.body.style.overflowY = 'unset';
		}

		// Cleanup: Reset overflowY when the component unmounts
		return () => {
			document.body.style.overflowY = 'unset';
		};
	}, [open]);
};
