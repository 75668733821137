"use client";
import { useChatsContext } from "@/context/chats";
import ChatsPreviewPlaceholder from "../Placeholders/ChatsPreviewPlaceholder";
import ChatPreview from "./ChatPreview";
import classes from "./ChatsPreview.module.css";

const ChatsPreview = ({
  chatHandler,
  sender,
  setChatSelection,
  chatSelection,
}) => {
  const { chats } = useChatsContext();

  return (
    <>
      <div className={classes.chatsContainerSidebar}>
        {!chats.loading && chats.chats.length === 0 ? (
          <div className={classes.noChats}>No chats</div>
        ) : (
          ""
        )}

        {!chats.loading ? (
          chats.chats.map((chat, index) => (
            <>
              <ChatPreview
                key={index}
                chat={chat}
                sender={sender}
                setChatSelection={setChatSelection}
                chatSelection={chatSelection}
                chatHandler={chatHandler}
              />
            </>
          ))
        ) : (
          <ChatsPreviewPlaceholder />
        )}
      </div>
    </>
  );
};

export default ChatsPreview;
