'use client';
import Cookies from 'js-cookie';
import { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from './';
import moment from 'moment';
// Create the cart context with default values
const ChatsContext = createContext({
	loaded: false,
	loading: false,
	count: 0,
	chats: [],
	maxed: false,
	notifications: 0,
	GET_ALL_CHATS: () => {},
	REMOVE_CHAT_NOT: () => {},
});

// Create a provider component for the cart context
export const ChatsProvider = ({ children }) => {
	const { auth, setAuth } = useAuthContext();

	const [chats, setChats] = useState({
		loaded: false,
		loading: false,
		count: 0,
		chats: [],
		maxed: false,
		notifications: 0,
	});

	const GET_ALL_CHATS = async () => {
		if (chats.loading) return;

		setChats((prevState) => ({
			...prevState,
			loading: true,
		}));

		await axios
			.get(`${process.env.NEXT_PUBLIC_SERVER_API}/api/chat/all-chats`, {
				headers: {
					'x-auth-token': Cookies.get('token'),
				},
			})
			.then(async (res) => {
				let chats = [...res.data].sort((a, b) =>
					moment(
						a.messages.length > 0 &&
							a.messages[a.messages.length - 1] &&
							a.messages[a.messages.length - 1].date
					).format('YYYY-MM-DD kk:mm:ss') <
					moment(
						b.messages.length > 0 &&
							b.messages[b.messages.length - 1] &&
							b.messages[b.messages.length - 1].date
					).format('YYYY-MM-DD kk:mm:ss')
						? 1
						: -1
				);
				let notifications = 0;
				let maxed = true;
				let count = chats.length;

				for (let i of chats) {
					if (i.status && i.notification.users.includes(auth.user._id)) {
						notifications = notifications + 1;
					}
				}

				setChats((prevState) => ({
					chats,
					notifications,
					maxed,
					count,
					loading: false,
					loaded: true,
				}));
			})
			.catch((err) => {
				console.log(err);
				setChats((prevState) => ({
					...prevState,
					loading: false,
					loaded: true,
				}));
			});
	};

	const REMOVE_CHAT_NOT = async ({ userId, chatId }) => {
		let newChats = [...chats.chats];
		//ALL
		let found1 = newChats.find((i) => i._id === chatId);
		if (found1) {
			let found2 = JSON.parse(JSON.stringify(found1));
			found2.notification.users = found2.notification.users.filter(
				(i) => i.toString() !== userId
			);
			newChats = newChats.filter((i) => i._id !== found2._id);
			newChats.push(found2);
		}

		newChats.sort((a, b) =>
			moment(
				a.messages.length > 0 &&
					a.messages[a.messages.length - 1] &&
					a.messages[a.messages.length - 1].date
			).format('YYYY-MM-DD kk:mm:ss') <
			moment(
				b.messages.length > 0 &&
					b.messages[b.messages.length - 1] &&
					b.messages[b.messages.length - 1].date
			).format('YYYY-MM-DD kk:mm:ss')
				? 1
				: -1
		);

		let notifications = 0;

		for (let i of newChats) {
			if (i.notification.users.includes(userId)) {
				notifications = notifications + 1;
			}
		}

		setChats((prevState) => ({
			chats: [...newChats],
			notifications,
			maxed: chats.maxed,
			count: chats.count,
		}));
	};

	useEffect(() => {
		if (auth.isAuthenticated) {
			GET_ALL_CHATS();
		}
	}, [auth.isAuthenticated]);

	return (
		<ChatsContext.Provider
			value={{
				chats,
				GET_ALL_CHATS,
				REMOVE_CHAT_NOT,
			}}
		>
			{children}
		</ChatsContext.Provider>
	);
};

// Custom hook to use the cart context
export const useChatsContext = () => {
	return useContext(ChatsContext);
};
