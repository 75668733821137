"use client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsClockHistory, BsDot } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import classes from "./ChatPreview.module.css";

const ChatPreview = ({
  sender,
  chat,
  setChatSelection,
  chatSelection,
  chatHandler,
}) => {
  let length = chat.messages.length - 1;

  const [receiver, setReceiver] = useState();

  useEffect(() => {
    setReceiver(sender._id === chat.buyer._id ? chat.seller : chat.buyer);
  }, [chat, sender]);

  const now = moment().format("YYYY-MM-DD kk:mm:ss");

  const chatSelectionHandler = () => {
    setChatSelection(chat);
    chatHandler(chat._id);
  };

  moment.locale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "seconds ago",
      ss: "%ss",
      m: "minutes ago",
      mm: "%dm",
      h: "an hour",
      hh: "%dh",
      d: "a day",
      dd: "%dd",
      M: "%dM",
      MM: "%dM",
      y: "a year",
      yy: "%dY",
    },
  });

  return (
    <>
      {receiver && sender && chat.messages.length > 0 && (
        <div
          className={
            chatSelection && chatSelection._id === chat._id
              ? classes.chatRowSelected
              : classes.chatRow
          }
          onClick={chatSelectionHandler}
        >
          {!chat.offer &&
            chat.order.approved === false &&
            chat.order.status === "requested" &&
            sender._id === chat.seller._id && (
              <div className={classes.pillOfferSent}>Approve now</div>
            )}

          <div className={classes.imagesSection}>
            <LazyLoadImage
              loading="lazy"
              src={`${
                chat.listing
                  ? chat.listing.images[0]
                  : chat.listings.length > 0 &&
                    chat.listings[0].listing &&
                    chat.listings[0].listing.images[0]
              }?tr=w-300,h-300`}
              alt={"used furniture image"}
              className={classes.listingImage}
            />
            {chat.listings && chat.listings.length > 1 && (
              <div className={classes.plusItems}>
                +{chat.listings.length - 1}
              </div>
            )}
          </div>

          <div className={classes.chatInfo}>
            <div className={classes.flexCol}>
              {chat.offer && chat.order.status === "offer-sent" ? (
                <>
                  <div className={classes.offerBadge}>Offer</div>
                </>
              ) : (
                <div className={classes.orderBadge}>
                  {chat.order.type === "delivery" ? (
                    <>Delivery order</>
                  ) : chat.order.scheduleViewing ? (
                    <>Viewing scheduled</>
                  ) : (
                    <>Pickup order</>
                  )}
                </div>
              )}
              <div className={classes.message}>
                <h6
                  className={
                    chat.notification.present &&
                    chat.notification.users.includes(sender._id.toString())
                      ? classes.infoTextNotification
                      : classes.infoText
                  }
                >
                  {chat.messages[length] &&
                  chat.messages[length].offer &&
                  chat.messages[length].offer.status === "counteroffer" ? (
                    chat.seller._id === sender._id ? (
                      <>You: </>
                    ) : (
                      <>
                        {receiver._id === chat.seller._id
                          ? "The seller"
                          : receiver.firstName}{" "}
                        has{" "}
                      </>
                    )
                  ) : chat.messages[length] && chat.messages[length].sender ? (
                    chat.messages[length].sender === sender._id ? (
                      <>You: </>
                    ) : (
                      chat.messages[length].type !== "text" && (
                        <>
                          {receiver._id === chat.seller._id
                            ? "The seller"
                            : receiver.firstName}{" "}
                          has{" "}
                        </>
                      )
                    )
                  ) : (
                    ""
                  )}
                  {chat.messages[length] ? (
                    chat.messages[length].type === "offer-sent" &&
                    chat.messages[length].offer &&
                    chat.messages[length].offer.status !== "counteroffer" ? (
                      <>
                        offered ${chat.offer.amount}{" "}
                        <div className={classes.originalPrice}>
                          $
                          {chat.listings[0] &&
                            chat.listings[0].listing &&
                            chat.listings[0].listing.price}
                        </div>
                      </>
                    ) : chat.messages[length].type === "update" ? (
                      chat.messages[length].seenBy.includes(sender._id) ? (
                        <> {chat.messages[length].text}</>
                      ) : (
                        <>
                          {receiver._id === chat.seller._id
                            ? "The seller"
                            : receiver.firstName}{" "}
                          has received an update
                        </>
                      )
                    ) : chat.messages[length].type === "text" ? (
                      <>{chat.messages[length].text}</>
                    ) : chat.messages[length].offer &&
                      chat.messages[length].offer.status &&
                      chat.messages[length].offer.status === "counteroffer" ? (
                      <>
                        sent a ${chat.messages[length].offer.counteroffer}{" "}
                        counter offer
                      </>
                    ) : chat.messages[length].type === "offer-deleted" ? (
                      <>deleted the offer</>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </h6>
                <h6 className={classes.sent}>
                  <BsDot className={classes.dot} />{" "}
                  {moment(
                    chat.messages[chat.messages.length - 1].date
                  ).fromNow()}
                </h6>
              </div>

              {chat.offer &&
                !chat.order.approved &&
                chat.order.status == "offer-sent" && (
                  <>
                    <div className={classes.countdownDiv}>
                      {moment(chat.offer.expiration).format(
                        "YYYY-MM-DD kk:mm:ss"
                      ) < now ? (
                        <div className={classes.expired}>Expired</div>
                      ) : (
                        <>
                          <BsClockHistory className={classes.expire} />
                          {chat.offer &&
                          chat.offer.counterofferStatus ===
                            "counteroffer-sent" &&
                          chat.offer.counterofferExpiration &&
                          moment(chat.offer.counterofferExpiration) >
                            moment() ? (
                            <>
                              Counter offer expires in{" "}
                              {moment(
                                chat.offer.counterofferExpiration &&
                                  chat.offer.counterofferExpiration
                              ).fromNow()}
                            </>
                          ) : (
                            <>
                              {" "}
                              Offer expires in{" "}
                              {moment(chat.offer.expiration).fromNow()}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
            </div>

            <div
              className={
                chat.notification.present &&
                chat.notification.users.includes(sender._id.toString())
                  ? classes.chatNotification
                  : undefined
              }
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatPreview;
