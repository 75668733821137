'use client';
import Cookies from 'js-cookie';
import { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '.';

// Create the cart context with default values
const PurchasesContext = createContext({
	openPurchases: {
		loading: false,
		loaded: false,
		orders: [],
	},
	closedPurchases: {
		loading: false,
		loaded: false,
		orders: [],
	},
	openOffers: {
		loading: false,
		loaded: false,
		orders: [],
	},
	closedOffers: {
		loading: false,
		loaded: false,
		orders: [],
	},

	LOAD_PURCHASES: () => {},
});

// Create a provider component for the cart context
export const PurchasesProvider = ({ children }) => {
	const { auth } = useAuthContext();

	const [purchases, setPurchases] = useState({
		openPurchases: {
			loading: false,
			loaded: false,
			orders: [],
		},
		closedPurchases: {
			loading: false,
			loaded: false,
			orders: [],
		},
		openOffers: {
			loading: false,
			loaded: false,
			orders: [],
		},
		closedOffers: {
			loading: false,
			loaded: false,
			orders: [],
		},
	});

	const LOAD_PURCHASES = async ({ type, reload }) => {
		if (purchases[type]?.loaded && !reload) {
			return;
		}

		setPurchases((prevState) => ({
			...prevState,
			[type]: { ...prevState[type], loading: true },
		}));

		// return;
		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/chat/purchases`,
				{
					type,
				},
				{
					headers: {
						'x-auth-token': Cookies.get('token'),
					},
				}
			)
			.then(async (res) => {
				setPurchases((prevState) => ({
					...prevState,
					[type]: { loading: false, loaded: true, orders: res.data },
				}));
			})
			.catch(async (error) => {
				console.log(error);
				setPurchases((prevState) => ({
					...prevState,
					[type]: { loading: false, loaded: true, orders: [] },
				}));
			});
	};

	useEffect(() => {
		if (auth.isAuthenticated) {
			LOAD_PURCHASES({ type: 'openPurchases', reload: true });
			LOAD_PURCHASES({ type: 'openOffers', reload: true });
		}
	}, [auth]);

	return (
		<PurchasesContext.Provider
			value={{
				purchases,
				LOAD_PURCHASES,
			}}
		>
			{children}
		</PurchasesContext.Provider>
	);
};

// Custom hook to use the cart context
export const usePurchasesContext = () => {
	return useContext(PurchasesContext);
};
