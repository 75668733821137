import { Suspense, useRef, useState } from 'react';
import SearchBar from './NavbarComponents/SearchBar';
import classes from './Navbar.module.css';
import MainNavigationMobile from './NavbarComponents/MainNavigationMobile';

const BuyNav = (props) => {
	const { auth } = props;
	const [expanded, setExpanded] = useState(false);
	const refToggle = useRef();

	return (
		<>
			<div className={classes.BuyNavbarOnScroll}>
				<Suspense fallback={<div></div>}>
					{expanded && (
						<MainNavigationMobile
							expanded={expanded}
							refToggle={refToggle}
							setExpanded={setExpanded}
							auth={auth}
						/>
					)}
				</Suspense>
				<div
					onClick={() => setExpanded(true)}
					ref={refToggle}
					className={classes.navToggle}
				>
					<svg viewBox="0 0 100 80" width="15" height="15">
						<rect width="100" height="15" rx="8"></rect>
						<rect y="30" width="100" height="15" rx="8"></rect>
						<rect y="60" width="100" height="15" rx="8"></rect>
					</svg>
				</div>
				<div className={classes.filterSectionMobile}>
					<SearchBar className={classes.searchBarClass} />
				</div>
			</div>
		</>
	);
};
export default BuyNav;
