'use client';

import { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '.';
import { useAlertContext } from './alert';

// Create the cart context with default values
const CartContext = createContext({
	loading: false,
	listings: [],

	LOAD_CART: () => {},
	ADD_TO_CART: () => {},
	REMOVE_FROM_CART: () => {},
	REMOVE_ITEMS_FROM_CART: () => {},
});

// Create a provider component for the cart context
export const CartProvider = ({ children }) => {
	const { auth } = useAuthContext();
	const { SET_ALERT } = useAlertContext();

	const [cart, setCart] = useState({
		loading: false,
		listings: [],
	});

	const ADD_TO_CART = async (listingId) => {
		setCart((prevState) => ({
			...prevState,
			loading: true,
		}));

		if (global?.window && !auth.isAuthenticated) {
			try {
				let currentCart = localStorage.cart
					? JSON.parse(localStorage.cart)
					: [];
				let i = currentCart.find((b) => b === listingId);
				if (i) {
					SET_ALERT({
						msg: 'Item is already present in your cart',
						type: 'danger',
					});

					setCart((prevState) => ({
						...prevState,
						loading: false,
					}));

					return;
				} else if (global?.window) {
					let newCart = localStorage.cart
						? [...currentCart, listingId]
						: [listingId];

					localStorage.setItem('cart', JSON.stringify([...new Set(newCart)]));

					SET_ALERT({
						msg: `Added to cart`,
						type: 'success',
					});

					await axios
						.post(
							`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/listing-with-ids`,
							{
								ids: newCart,
								status: 'active',
							}
						)
						.then((res) => {
							setCart((prevState) => ({
								listings: res.data,
								loading: false,
							}));
						})
						.catch((err) => {
							console.log(err);
							setCart((prevState) => ({
								...prevState,
								loading: false,
							}));
						});
				}
			} catch (err) {
				console.log(err);

				SET_ALERT({
					msg: 'Error adding item to your cart',
					type: 'danger',
				});

				setCart((prevState) => ({
					...prevState,
					loading: false,
				}));
			}
		} else {
			for (let item of cart.listings) {
				if (item === listingId) {
					SET_ALERT({
						msg: 'Item is already present in your cart',
						type: 'danger',
					});

					setCart((prevState) => ({
						...prevState,
						loading: false,
					}));
					return;
				}
			}

			let userCart = [];

			await axios
				.post(`${process.env.NEXT_PUBLIC_SERVER_API}/api/cart/${listingId}`)
				.then((res) => {
					SET_ALERT({
						msg: `Added to cart`,
						type: 'success',
					});

					setCart((prevState) => ({
						listings: res.data,
						loading: false,
					}));
					userCart = res.data;
				})
				.catch((error) => {
					setCart((prevState) => ({
						...prevState,
						loading: false,
					}));
				});

			let segmentCart = [];
			for (let listing of userCart) {
				if (listing.status === 'active') {
					segmentCart.push({
						item_id: listing._id,
						item_name: listing.title,
						item_estRetailPrice:
							listing.estRetailPrice &&
							listing.estRetailPrice !== 'null' &&
							listing.estRetailPrice !== 'undefined'
								? listing.estRetailPrice
								: null,
						item_image: listing.images[0],
						item_brand: listing.brand
							? listing.brand
							: listing.velouAttributes?.manufacturer?.length > 0
							? listing.velouAttributes?.manufacturer[0]
									?.replace(/_/g, ' ')
									?.split(' ')
									?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									?.join(' ')
							: listing.velouAttributes?.brands?.length > 0
							? listing.velouAttributes?.brands[0]
									?.replace(/_/g, ' ')
									?.split(' ')
									?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									?.join(' ')
							: '',
						item_category: listing.newSchema.categories[0],
						item_category2: listing.newSchema.subCategories[0]
							? listing.newSchema.subCategories[0]
							: '',
						item_category3: listing.newSchema.types[0]
							? listing.newSchema.types[0]
							: '',
						item_price: listing.price,
					});
				}
			}

			process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
				process.env.OG_DB === 'true' &&
				global?.window &&
				window.analytics.identify(
					auth.user._id,
					global?.window && {
						cart: segmentCart.length > 0 ? JSON.stringify(segmentCart) : '',
						firstName: auth.user.firstName,
						lastName: auth.user.lastName ? auth.user.lastName : '',
						phone: auth.user.phone ? auth.user.phone : '',

						email: auth.user.email,
					}
				);
		}
	};

	const REMOVE_FROM_CART = async (listingId) => {
		setCart((prevState) => ({
			...prevState,
			loading: true,
		}));
		if (!auth.isAuthenticated) {
			try {
				let currentCart = JSON.parse(localStorage.cart);
				let newCart = currentCart.filter((b) => b !== listingId);

				localStorage.setItem('cart', JSON.stringify([...new Set(newCart)]));

				SET_ALERT({
					msg: `Item removed from cart`,
					type: 'success',
				});

				await axios
					.post(
						`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/listing-with-ids`,
						{
							ids: newCart,
							status: 'active',
						}
					)
					.then((res) => {
						setCart((prevState) => ({
							listings: res.data,
							loading: false,
						}));
					})
					.catch((err) => {
						console.log(err);
						setCart((prevState) => ({
							...prevState,
							loading: false,
						}));
					});
			} catch {
				SET_ALERT({
					msg: 'Error adding item to your cart',
					type: 'danger',
				});

				setCart((prevState) => ({
					...prevState,
					loading: false,
				}));
			}
		} else {
			let userCart = [];
			await axios
				.post(
					`${process.env.NEXT_PUBLIC_SERVER_API}/api/cart/remove/${listingId}`
				)
				.then(async (res) => {
					setCart((prevState) => ({
						listings: res.data,
						loading: true,
					}));

					userCart = res.data;
				})
				.catch((error) => {
					setCart((prevState) => ({
						...prevState,
						loading: false,
					}));
				});
			let segmentCart = [];
			for (let listing of userCart) {
				if (listing.status === 'active') {
					segmentCart.push({
						item_id: listing._id,
						item_name: listing.title,
						item_estRetailPrice:
							listing.estRetailPrice &&
							listing.estRetailPrice !== 'null' &&
							listing.estRetailPrice !== 'undefined'
								? listing.estRetailPrice
								: null,
						item_image: listing.images[0],
						item_brand: listing.brand
							? listing.brand
							: listing.velouAttributes?.manufacturer?.length > 0
							? listing.velouAttributes?.manufacturer[0]
									?.replace(/_/g, ' ')
									?.split(' ')
									?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									?.join(' ')
							: listing.velouAttributes?.brands?.length > 0
							? listing.velouAttributes?.brands[0]
									?.replace(/_/g, ' ')
									?.split(' ')
									?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									?.join(' ')
							: '',
						item_category: listing.newSchema.categories[0],
						item_category2: listing.newSchema.subCategories[0]
							? listing.newSchema.subCategories[0]
							: '',
						item_category3: listing.newSchema.types[0]
							? listing.newSchema.types[0]
							: '',
						item_price: listing.price,
					});
				}
			}

			process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
				process.env.OG_DB === 'true' &&
				global?.window &&
				window.analytics.identify(auth.user._id, {
					cart: segmentCart.length > 0 ? JSON.stringify(segmentCart) : '',
					firstName: auth.user.firstName,
					lastName: auth.user.lastName ? auth.user.lastName : '',
					phone: auth.user.phone ? auth.user.phone : '',

					email: auth.user.email,
				});

			setCart((prevState) => ({
				...prevState,
				loading: false,
			}));
		}
	};

	const REMOVE_ITEMS_FROM_CART = async (listings) => {
		setCart((prevState) => ({
			...prevState,
			loading: true,
		}));

		if (global?.window && !auth.isAuthenticated) {
			let currentCart = JSON.parse(localStorage.cart);

			for (let listing of listings) {
				currentCart = currentCart.filter((b) => b !== listing);
			}

			localStorage.setItem('cart', JSON.stringify([...new Set(currentCart)]));

			setCart((prevState) => ({
				listings: currentCart,
				loading: false,
			}));
			return;
		} else if (global?.window) {
			let userCart = [];
			await axios
				.post(
					`${process.env.NEXT_PUBLIC_SERVER_API}/api/cart/remove-from-cart/multiple`,
					{
						listings,
					}
				)
				.then(async (res) => {
					setCart((prevState) => ({
						listings: res.data,
						loading: false,
					}));
					userCart = res.data;
				})
				.catch((error) => {
					setCart((prevState) => ({
						...prevState,
						loading: false,
					}));
				});
			let segmentCart = [];
			for (let listing of userCart) {
				if (listing.status === 'active') {
					segmentCart.push({
						item_id: listing._id,
						item_name: listing.title,
						item_image: listing.images[0],
						item_estRetailPrice:
							listing.estRetailPrice &&
							listing.estRetailPrice !== 'null' &&
							listing.estRetailPrice !== 'undefined'
								? listing.estRetailPrice
								: null,
						item_brand: listing.brand
							? listing.brand
							: listing.velouAttributes?.manufacturer?.length > 0
							? listing.velouAttributes?.manufacturer[0]
									?.replace(/_/g, ' ')
									?.split(' ')
									?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									?.join(' ')
							: listing.velouAttributes?.brands?.length > 0
							? listing.velouAttributes?.brands[0]
									?.replace(/_/g, ' ')
									?.split(' ')
									?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									?.join(' ')
							: '',
						item_category: listing.newSchema.categories[0],
						item_category2: listing.newSchema.subCategories[0]
							? listing.newSchema.subCategories[0]
							: '',
						item_category3: listing.newSchema.types[0]
							? listing.newSchema.types[0]
							: '',
						item_price: listing.price,
					});
				}
			}

			process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
				process.env.OG_DB === 'true' &&
				global?.window &&
				window.analytics.identify(auth.user._id, {
					cart: segmentCart.length > 0 ? JSON.stringify(segmentCart) : '',
					firstName: auth.user.firstName,
					lastName: auth.user.lastName ? auth.user.lastName : '',
					phone: auth.user.phone ? auth.user.phone : '',

					email: auth.user.email,
				});
		}
	};

	const LOAD_CART = async () => {
		let allItems =
			!auth?.loading && auth?.isAuthenticated
				? auth?.user.cart
				: global?.window && localStorage.getItem('cart')
				? JSON.parse(localStorage.getItem('cart'))
				: [];
		if (allItems?.length === 0) return;

		setCart((prevState) => ({
			...prevState,
			loading: true,
		}));

		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/listing-with-ids`,
				{
					ids: allItems,
					status: 'active',
				}
			)
			.then((res) => {
				setCart((prevState) => ({
					listings: res.data,
					loading: false,
				}));
			})
			.catch((err) => {
				console.log(err);
				setCart((prevState) => ({
					...prevState,
					loading: false,
				}));
			});

		setCart((prevState) => ({
			...prevState,
			loading: false,
		}));
	};

	useEffect(() => {
		LOAD_CART();
	}, [auth]);

	return (
		<CartContext.Provider
			value={{
				cart,
				LOAD_CART,
				ADD_TO_CART,
				REMOVE_FROM_CART,
				REMOVE_ITEMS_FROM_CART,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

// Custom hook to use the cart context
export const useCartContext = () => {
	return useContext(CartContext);
};
