'use client';
import AlertMsg from './AlertMsg';
import { useAlertContext } from '@/context/alert';

const Alert = () => {
	const { alert } = useAlertContext();

	return (
		<>
			{alert?.alerts?.length > 0
				? alert.alerts.map((alert) => <AlertMsg alert={alert} key={alert.id} />)
				: ''}
		</>
	);
};

export default Alert;
