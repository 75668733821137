'use client';
import { motion } from 'framer-motion';
import classes from '../Navbar.module.css';
import Link from 'next/link';
import { useAuthContext } from '@/context/';

const ProfileModal = (props) => {
	const { auth, setAuth, refNavMenu, setShowNavMenu } = props;

	const { LOGOUT } = useAuthContext();

	const logoutHandler = async () => {
		LOGOUT();
	};

	return (
		<>
			<motion.div
				initial={{ opacity: 0, x: '100px' }}
				animate={{ opacity: 1, x: 0 }}
				transition={{
					duration: 0.4,
					type: 'spring',
					stiffness: 400,
					damping: 40,
				}}
				className={classes.profileNavMenu}
				ref={refNavMenu}
				onClick={() => setShowNavMenu(false)}
			>
				<Link
					href={`/profile/${auth.user._id}`}
					className={classes.profileNavMenuItemTop}
				>
					<img
						className={classes.profilePic}
						src={`${auth.user.picture}?tr=w-60,h-60`}
						referrerPolicy="no-referrer"
						alt="Profile Icon"
					/>
					<div className={classes.profileInfo}>
						<h4 className={classes.nameText}>
							{auth.user.firstName}{' '}
							{auth.user.lastName && auth.user.lastName !== 'undefined' ? (
								<>{auth.user.lastName}</>
							) : (
								''
							)}
						</h4>
						<div className={`${classes.profileText} `}>
							See your {auth.user.isBusiness ? 'shop' : 'profile'}
						</div>
					</div>
				</Link>
				<h6 className={classes.dropDownContent}>Selling</h6>
				{auth.user.isBusiness && (
					<Link
						href={`/seller-dashboard?tab=home`}
						className={classes.profileNavMenuItem}
					>
						<div className={classes.profileInfo}>
							<div className={classes.smallText}>Dashboard</div>
						</div>
					</Link>
				)}

				{!auth.user.isBusiness && (
					<Link
						href={`/consign-with-us`}
						className={classes.profileNavMenuItem}
					>
						<div className={classes.profileInfo}>
							<div className={classes.smallText}>Start Selling</div>
						</div>
					</Link>
				)}

				<Link
					href={
						auth.user.isBusiness
							? '/seller-dashboard?tab=products'
							: `/your-listings`
					}
					className={classes.profileNavMenuItem}
				>
					<div className={classes.profileInfo}>
						<div className={classes.smallText}>
							My {auth?.user?.isBusiness ? 'Products' : 'Consignments'}
						</div>
					</div>
				</Link>

				{auth?.user?.isBusiness && (
					<Link href={'/sales'} className={classes.profileNavMenuItem}>
						<div className={classes.profileInfo}>
							<div className={classes.smallText}>My Sales</div>
						</div>
					</Link>
				)}

				<h6 className={classes.dropDownContent}>Buying</h6>

				<Link href={`/purchases`} className={classes.profileNavMenuItem}>
					<div className={classes.profileInfo}>
						<div className={classes.smallText}>My Purchases</div>
					</div>
				</Link>

				<Link href={`/favorites`} className={classes.profileNavMenuItem}>
					<div className={classes.profileInfo}>
						<div className={classes.smallText}>Favorites</div>
					</div>
				</Link>
				<div className={classes.separator1} />
				{auth.user.admin && (
					<a
						target="_blank"
						rel="noreferrer"
						href={`https://admin.kashew.com`}
						className={classes.profileNavMenuItem}
					>
						<div className={classes.profileInfo}>
							<div className={classes.smallText}>Admin</div>
						</div>
					</a>
				)}
				<Link
					href={
						auth.user.isBusiness
							? '/seller-dashboard?tab=shop_details'
							: `/settings`
					}
					className={classes.profileNavMenuItem}
				>
					<div className={classes.profileInfo}>
						<div className={classes.smallText}>
							{auth.user.isBusiness ? 'Shop' : ''} Settings
						</div>
					</div>
				</Link>
				<li onClick={logoutHandler} className={classes.profileNavMenuItem}>
					<div className={classes.profileInfo}>
						<div className={classes.smallText}>Logout</div>
					</div>
				</li>
			</motion.div>
		</>
	);
};

export default ProfileModal;
