import classes from './Placeholder.module.css';

const ChatsPreviewPlaceholder = () => {
	const arr = [1, 2, 3];

	return (
		<>
			<div className={classes.main}>
				<div className={classes.topSection}>
					<div className={classes.singleTextPlaceholder} />
				</div>
				<div className={classes.bottomSection}>
					{arr.map((i) => (
						<div key={i} className={classes.chatPreviewPlaceholder}>
							<div className={classes.imagePlaceholder} />
							<div className={classes.flexCol}>
								<div className={classes.smallTextPlaceholder} />
								<div className={classes.smallTextPlaceholder} />
								<div className={classes.smallTextPlaceholder2} />
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default ChatsPreviewPlaceholder;
