'use client';

import Cookies from 'js-cookie';
import axios from 'axios';
import { useAuthContext } from '@/context';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import getLocation from '@/utils/getLocation';

const AppLayoutLogic = () => {
	const pathname = usePathname();
	const router = useRouter();

	const { auth, LOGIN_SUCCESS, LOAD_USER } = useAuthContext();
	const params = useSearchParams();

	// useEffect(() => {
	// 	const isFromGoogleReferrer = document.referrer.includes('google');
	// 	const urlParams = new URLSearchParams(window.location.search);
	// 	const searchQuery = urlParams.get('q');
	// 	const isFromGoogleQueryParam = searchQuery !== null;

	// 	if (isFromGoogleReferrer || isFromGoogleQueryParam) {
	// 		window.location.reload();
	// 	}

	// 	componentDidMount();
	// }, []);

	if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
		console.log = function () {};
	}

	useEffect(() => {
		const isBot = /bot|crawl|slurp|spider|Prerender|facebookexternalhit/i.test(
			navigator.userAgent
		);
		if (!isBot) {
			process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
				window.analytics?.page(window.location.pathname);
		}
	}, [pathname]);

	const redirectToOrderPage = async (id) => {
		await axios
			.get(`${process.env.NEXT_PUBLIC_SERVER_API}/api/orders/${id}`)
			.then((res) => {
				if (res.data.buyer._id === auth.user._id) {
					router.push(
						`/purchases?&tab=purchase&selection=openPurchases&order=${id}`
					);
				} else {
					router.push(`/sales?&tab=sales&selection=openSales&order=${id}`);
				}
			});
	};

	useEffect(() => {
		if (
			auth.isAuthenticated &&
			params.get('order') &&
			params.get('order') !== 'null' &&
			params.get('order') !== 'undefined' &&
			!window.location.pathname.includes('purchases') &&
			!window.location.pathname.includes('sales') &&
			!window.location.pathname.includes('orders')
		) {
			redirectToOrderPage(params.get('order'));
		}
	}, [pathname, auth]);

	const [firstLoad, setFirstLoad] = useState();
	const enterAccount = async () => {
		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/admin/enter-account`,
				{
					id: params.get('userAccountAdmin'),
				},
				{
					headers: {
						'x-auth-token': Cookies.get('token'),
					},
				}
			)
			.then(async (res) => {
				router.replace(pathname);
				LOGIN_SUCCESS(res.data);
				LOAD_USER();
			})
			.catch((err) => {});

		setFirstLoad(true);
	};

	useEffect(() => {
		if (
			!auth.loading &&
			auth.isAuthenticated &&
			auth.user.admin &&
			params.get('userAccountAdmin') &&
			params.get('userAccountAdmin') !== 'undefined' &&
			params.get('userAccountAdmin') !== 'null' &&
			!firstLoad
		) {
			enterAccount();
		}

		if (
			params.get('utm_source') &&
			params.get('utm_source') !== 'undefined' &&
			params.get('utm_source') !== 'null' &&
			!localStorage.getItem('source')
		) {
			localStorage.setItem('source', params.get('utm_source'));
		}
	}, [auth, firstLoad]);

	useEffect(() => {
		if (
			auth.isAuthenticated &&
			auth.user.locationInfo?.coordinates?.length > 0 &&
			!localStorage.getItem('region')
		) {
			localStorage.setItem('lat', auth.user.locationInfo.coordinates[1]);
			localStorage.setItem('long', auth.user.locationInfo.coordinates[0]);
			let region = getLocation(
				auth.user.locationInfo.coordinates[1],
				auth.user.locationInfo.coordinates[0]
			);
			if (region) {
				localStorage.setItem('region', region);
			}
		}
	}, [auth]);

	useEffect(() => {
		if (
			localStorage.getItem('lat') &&
			localStorage.getItem('long') &&
			!localStorage.getItem('region')
		) {
			let region = getLocation(
				localStorage.getItem('lat'),
				localStorage.getItem('long')
			);
			if (region) {
				localStorage.setItem('region', region);
			}
		}
	}, []);

	const componentDidMount = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return <></>;
};

export default AppLayoutLogic;
