import locations from './locations';

const getLocation = (lat2, long2) => {
	if (lat2 && long2) {
		let option = 'NA';
		let distOpt = null;
		for (let location of locations) {
			let lat1 = location.lat;
			let long1 = location.long;

			const radlat1 = (Math.PI * lat1) / 180;
			const radlat2 = (Math.PI * lat2) / 180;

			const theta = long1 - long2;
			const radtheta = (Math.PI * theta) / 180;

			let dist =
				Math.sin(radlat1) * Math.sin(radlat2) +
				Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

			if (dist > 1) {
				dist = 1;
			}

			dist = Math.acos(dist);
			dist = (dist * 180) / Math.PI;
			dist = dist * 60 * 1.1515;

			if (dist < 120) {
				if (option === 'NA' || (distOpt && distOpt > dist)) {
					option = location.id;
					distOpt = dist;
				}
			}
		}

		return option;
	}
};

export default getLocation;
