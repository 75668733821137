'use client';
import Cookies from 'js-cookie';
import { createContext, useState, useContext } from 'react';
import axios from 'axios';

// Create the cart context with default values
const SellerReviews = createContext({
	reviews: [],
	loading: false,
	LOAD_REVIEW: () => {},
});

// Create a provider component for the cart context
export const SellerReviewsProvider = ({ children }) => {
	const [sellerReviews, setSellerReviews] = useState({
		reviews: [],
		loading: false,
	});

	const LOAD_REVIEW = async (id, page) => {
		if (!id) return;
		if (sellerReviews.loading) return;
		setSellerReviews((prevState) => ({
			reviews: prevState.reviews,
			loading: true,
		}));

		let initialState = JSON.parse(JSON.stringify(sellerReviews));

		let found = initialState.reviews?.find((review) => review.id === id);
		if (page <= found?.page) {
			setSellerReviews((prevState) => ({
				reviews: prevState.reviews,
				loading: false,
			}));
			return;
		}

		let newReviews = found
			? initialState.reviews.map((review) =>
					review.id === id
						? {
								...review,
								page,
						  }
						: review
			  )
			: [
					...initialState.reviews,
					{
						id,
						page,
					},
			  ];

		setSellerReviews((prevState) => ({ reviews: newReviews, loading: true }));

		// return;
		await axios
			.get(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/review/seller-reviews/${id}?page=${page}`,
				{
					headers: {
						'x-auth-token': Cookies.get('token'),
					},
				}
			)
			.then(async (response) => {
				const { reviews, totalCount, reviewedCount, averageReview } =
					response.data;

				let newReviews2 = newReviews.map((review) =>
					review.id === id
						? {
								id,
								page,
								reviews:
									review.reviews?.length > 0
										? [...review.reviews, ...reviews]
										: reviews,
								totalCount,
								reviewedCount,
								averageReview,
						  }
						: review
				);

				setSellerReviews((prevState) => ({
					reviews: newReviews2,
				}));
			})
			.catch(async (error) => {
				console.log(error);
				setSellerReviews((prevState) => initialState);
			});
	};

	return (
		<SellerReviews.Provider
			value={{
				sellerReviews,
				LOAD_REVIEW,
			}}
		>
			{children}
		</SellerReviews.Provider>
	);
};

// Custom hook to use the cart context
export const useSellerReviewsContext = () => {
	return useContext(SellerReviews);
};
