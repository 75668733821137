'use client';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import classes from './AskReview.module.css';
import { useAuthContext } from '@/context';
import { useAlertContext } from '@/context/alert';
import Cookies from 'js-cookie';

const AskReview = () => {
	const { auth } = useAuthContext();
	const { SET_ALERT } = useAlertContext();

	const [review, setReview] = useState();
	const [requestGoogle, setRequestGoogle] = useState(false);

	const getReviews = async (id) => {
		await axios
			.get(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/review/get-reviews`,
				{
					headers: { 'x-auth-token': Cookies.get('token') },
				}
			)
			.then((res) => {
				if (res.data) {
					setReview(res.data);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (!auth.loading && auth.isAuthenticated) {
			getReviews();
		}
	}, [auth]);

	const handleChange = (e) => {
		let newReview = JSON.parse(JSON.stringify(review));
		if (e.target.name === 'buyer.rating') {
			newReview.buyer.rating = e.target.value;
		} else if (e.target.name === 'seller.rating') {
			newReview.seller.rating = e.target.value;
		} else if (e.target.name === 'seller.feedback') {
			newReview.seller.feedback = e.target.value;
		} else if (e.target.name === 'buyer.feedback') {
			newReview.buyer.feedback = e.target.value;
		} else if (e.target.name === 'listing.rating') {
			newReview.listings[e.target.idx].rating = e.target.value;
		} else if (e.target.name === 'listing.feedback') {
			newReview.listings[e.target.idx].feedback = e.target.value;
		}

		setReview(newReview);
	};

	const submitHandler = async () => {
		const data = new FormData();

		data.append('buyer', JSON.stringify(review.buyer));
		data.append('seller', JSON.stringify(review.seller));
		// data.append("listings", JSON.stringify(review.listings));
		data.append('id', JSON.stringify(review._id));

		// if (picture) {
		//   data.append("picture", picture);
		// }

		if (review.type === 'completedPurchase' && !review.seller.rating) {
			return;
		} else if (review.type === 'completedSale' && !review.buyer.rating) {
			return;
		}

		await axios
			.post(`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/review/`, data)
			.then((res) => {
				if (review.type === 'completedPurchase' && review.seller.rating > 3) {
					setRequestGoogle(true);
				} else if (review.type === 'completedSale' && review.buyer.rating > 3) {
					setRequestGoogle(true);
				} else {
					setReview();

					SET_ALERT({ msg: 'Thank you for your review!', type: 'success' });
				}
			})
			.catch((err) => {
				console.log(err);
				SET_ALERT({ msg: 'Server error', type: 'danger' });
			});
	};

	const handleNoReview = async () => {
		await axios
			.get(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/review/no-review/${review._id}`
			)
			.then((res) => {
				setReview();
			})
			.catch((err) => {
				console.log(err);
				setReview();
			});
	};

	//   const pictureChangeHandler = (e) => {
	//     setPicture(e.target.files[0]);
	//     setPreview(URL.createObjectURL(e.target.files[0]));
	//   };

	return (
		<>
			{review && review.listings?.length > 0 && review.listings[0]?.listing && (
				<Modal show={review} className={classes.modal}>
					{requestGoogle ? (
						<>
							<section className={classes.body}>
								{review.type === 'completedSale' ? (
									<>
										<section className={classes.gglReviewTop}>
											<img
												src="https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/logo/svg/logo-black.svg"
												alt="Kashew Logo"
												className={classes.logo}
											/>
											<h4 className={classes.gglReviewText}>
												We're so glad you chose us! If you enjoyed your
												experience, we'd be grateful if you could share it with
												a{' '}
												<a
													href={`https://g.page/r/CVlsXUTHm2XZEBM/review`}
													target="_blank"
													rel="noreferrer"
												>
													Google review
												</a>
												. It helps us grow and ensures we keep meeting your
												expectations. Thank you for your trust and support!
											</h4>
										</section>

										<div className={classes.bottomSection}>
											<button
												className={classes.buttonRenew}
												onClick={() => setReview()}
											>
												Done
											</button>
										</div>
									</>
								) : (
									<>
										<section className={classes.gglReviewTop}>
											<img
												src="https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/logo/svg/logo-black.svg"
												alt="Kashew Logo"
												className={classes.logo}
											/>
											<h4 className={classes.gglReviewText}>
												We're so glad you chose us! If you enjoyed your
												experience, we'd be grateful if you could share it with
												a{' '}
												<a
													href={`https://g.page/r/CVlsXUTHm2XZEBM/review`}
													target="_blank"
													rel="noreferrer"
												>
													Google review
												</a>
												. It helps us grow and ensures we keep meeting your
												expectations. Thank you for your trust and support!
											</h4>
										</section>

										<div className={classes.bottomSection}>
											<button
												className={classes.buttonRenew}
												onClick={() => setReview()}
											>
												Done
											</button>
										</div>
									</>
								)}
							</section>
						</>
					) : (
						<>
							<section className={classes.body}>
								<div className={classes.topSection}>
									<section>
										<h4 className={classes.reviewText}>
											<div className={classes.marginBottom}>
												Hi{' '}
												{review.type === 'completedSale'
													? review.seller.user.firstName
													: review.buyer.user.firstName}
												,
											</div>
										</h4>
										<h4 className={classes.reviewText}>
											You recently{' '}
											{review.type === 'completedSale' ? 'sold' : 'bought'}{' '}
											something{' '}
											{review.type === 'completedSale' ? 'to ' : 'from '}
											<b className={classes.font}>
												{review.type === 'completedSale'
													? review.buyer.user.firstName
													: review.seller.user.firstName}
											</b>
											<br />
											Tell us how they did!
										</h4>
									</section>

									<img
										src={
											review.type === 'completedSale'
												? review.listings[0].listing.images[0]
												: review.listings[0].listing.images[0]
										}
										alt={'user profile'}
										className={classes.imgProfile}
									/>
								</div>
								<section className={classes.bottomSection}>
									<div className={classes.rateSection}>
										<h6 className={classes.modalText}>
											Rate the{' '}
											{review.type === 'completedSale' ? 'buyer' : 'seller'}
										</h6>
										<Rating
											onClick={(e) =>
												handleChange({
													target: {
														name:
															review.type === 'completedSale'
																? 'buyer.rating'
																: 'seller.rating',
														value: e,
													},
												})
											}
											ratingValue={
												review.type === 'completedSale'
													? review.buyer.rating
													: review.seller.rating
											}
										/>
									</div>
									<div className={classes.rateSection}>
										<h6 className={classes.modalText1}>Review</h6>

										<textarea
											className={classes.textArea}
											placeholder="Write your review..."
											onChange={(e) => handleChange(e)}
											value={
												review.type === 'completedSale'
													? review.buyer.feedback
													: review.seller.feedback
											}
											name={
												review.type === 'completedSale'
													? 'buyer.feedback'
													: 'seller.feedback'
											}
										/>
									</div>

									{/* {review.type === 'completedPurchase' && (
										<div className={classes.reviewBox}>
											Add a picture of your new piece!
											{preview && (
												<div className={classes.imgWrapper}>
													<img src={preview} className={classes.img} />
												</div>
											)}
											<input
												type="file"
												name="picture"
												placeholder="Add a picture of your new piece!"
												accept=".png, .jpg, .jpeg"
												onChange={(e) => pictureChangeHandler(e)}
												className={classes.fileInput}
											/>
										</div>
									)} */}
									<div className={classes.buttons}>
										<div
											className={classes.buttonRenew}
											onClick={submitHandler}
										>
											Submit
										</div>
										<div
											className={classes.button}
											onClick={() => handleNoReview()}
										>
											Not now{' '}
										</div>
									</div>
								</section>

								{/* <div className={classes.mainBody}>
                  <h3 className={classes.modalText}>
                    Rate The{" "}
                    {review.type === "completedSale" ? "Buyer" : "Seller"}
                  </h3>
                  <section className={classes.section}>
                    <img
                      src={
                        review.type === "completedSale"
                          ? review.buyer.user.picture
                          : review.seller.user.picture
                      }
                      alt={"user picture"}
                      className={classes.imgProfile}
                    />

                    <div className={classes.moreInfo}>
                      <h4 className={classes.size}>
                        {review.type === "completedSale"
                          ? review.buyer.user.firstName
                          : review.seller.user.firstName}
                      </h4>
                    </div>
                  </section>
                  <h3 className={classes.modalText}>Your Overall Rating</h3>

                  <div className={classes.reviewBox}>
                    <Rating
                      onClick={(e) =>
                        handleChange({
                          target: {
                            name:
                              review.type === "completedSale"
                                ? "buyer.rating"
                                : "seller.rating",
                            value: e,
                          },
                        })
                      }
                      ratingValue={
                        review.type === "completedSale"
                          ? review.buyer.rating
                          : review.seller.rating
                      }
                    />
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Share more feedback (optional)"
                        value={
                          review.type === "completedSale"
                            ? review.buyer.feedback
                            : review.seller.feedback
                        }
                        rows={4}
                        name={
                          review.type === "completedSale"
                            ? "buyer.feedback"
                            : "seller.feedback"
                        }
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                  </div>
                </div> */}

								{/* {review.type === "completedPurchase" && (
                  <>
                    <div className={classes.mainBody}>
                      Rate the products
                      {review.listings.map((i, idx) => (
                        <div key={i.listing._id}>
                          {console.log(i)}
                          {console.log(idx)}
                          <div className={classes.imgContainer}>
                            <img
                              src={i.listing.images[0]}
                              alt={
                                i.listing.title ? i.listing.title : "listing"
                              }
                              className={classes.img}
                            />
                          </div>

                          <div className={classes.moreInfo}>
                            <h4 className={classes.size}>{i.listing.title}</h4>
                            <Rating
                              ratingValue={i.rating}
                              onClick={(e) =>
                                handleChange({
                                  target: {
                                    name: "listing.rating",
                                    idx: idx,
                                    value: e,
                                  },
                                })
                              }
                            />
                            <div className={classes.reviewBox}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Control
                                  as="textarea"
                                  placeholder="Share more feedback (optional)"
                                  rows={4}
                                  value={i.feedback}
                                  onChange={(e) =>
                                    handleChange({
                                      target: {
                                        name: "listing.feedback",
                                        idx: idx,
                                        value: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={classes.reviewBox}>
                      Add a picture of your new piece!
                      {preview && (
                        <div className={classes.imgWrapper}>
                          <img src={preview} className={classes.img} />
                        </div>
                      )}
                      <input
                        type="file"
                        name="picture"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => pictureChangeHandler(e)}
                        className={classes.fileInput}
                      />
                    </div>
                  </>
                )} */}
							</section>
						</>
					)}
				</Modal>
			)}
		</>
	);
};

export default AskReview;
