'use client';

import classes from './InfoNavBarSales.module.css';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { usePathname } from 'next/navigation';

const InfoNavBarSales = () => {
	const pathname = usePathname();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [direction, setDirection] = useState(1);
	const [innerWidth, setInnerWidth] = useState();

	useEffect(() => {
		setInnerWidth(window.innerWidth);
	}, []);

	const variants = {
		enter: (direction) => ({
			y: -50, // Start above, moving down regardless of direction
			opacity: 0,
		}),
		center: {
			zIndex: 1,
			y: 0,
			opacity: 1,
		},
		exit: (direction) => ({
			zIndex: 0,
			y: 50, // Exit downwards regardless of direction
			opacity: 0,
		}),
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setDirection(1); // Always flip to the bottom
			setCurrentIndex((prevIndex) => (prevIndex + 1) % rotatingText.length);
		}, 10000); // Rotate every 8 seconds

		return () => clearInterval(interval);
	}, []);

	const rotatingText = [
		// <div key="sale">
		// 	🌸 Spring Sale! Take $30 Off with the code{' '}
		// 	<span
		// 		className={classes.underline}
		// 		onClick={() => copyToClipBoard('WELCOME30')}
		// 	>
		// 		WELCOME30
		// 	</span>{' '}
		// 	(on orders $300+)
		// </div>,
		<div key="questions">
			🌼 Questions? Call <span className={classes.bold}>(213) 556-2728</span> or
			email <span className={classes.bold}>support@kashew.com</span>
		</div>,
		<div key="premium" className={classes.rowGap}>
			<span>🚚 Premium delivery service</span> <span>🤝 Buyer protection</span>{' '}
			<span>🌺 All items curated {innerWidth > 600 && 'by Kashew'}</span>
		</div>,
		<div key="Interior designers" className={classes.rowGap}>
			<span>👩‍🎨 Are you an interior designer?</span>{' '}
			<a href={`/trade`} target="_blank" style={{ color: 'white' }}>
				Discover our trade program
			</a>{' '}
		</div>,
	];

	return (
		<>
			{pathname !== '/login' && pathname !== '/register' && (
				<motion.section className={classes.stickyNav}>
					<AnimatePresence mode="wait">
						<motion.div
							className={classes.main}
							key={currentIndex} // This key change is crucial for triggering enter and exit animations
							initial="enter"
							animate="center"
							exit="exit" // No need to conditionally set this based on direction for your use case
							variants={variants}
							custom={direction}
							transition={{ duration: 0.8 }}
						>
							{rotatingText[currentIndex]}
						</motion.div>
					</AnimatePresence>
				</motion.section>
			)}
		</>
	);
};
export default InfoNavBarSales;
