"use client";
import { Form } from "react-bootstrap";
import Modal from "@/components/UI/CustomModal";
import classes from "./BlockedAccount.module.css";
import { useAuthContext } from "@/context";

const BlockedAccount = () => {
  const { auth } = useAuthContext();

  return (
    <>
      {!auth.loading && auth.isAuthenticated && auth.user.blocked && (
        <div className={classes.overlay}>
          <div className={classes.modal}>
            <h3 className={classes.header}>Your account has been blocked</h3>
            <div className={classes.container}>
              <div className={classes.textDiv}>
                Your account has been blocked because it has breached Kashew's
                community guidlines. To appeal this decision, please email
                support@kashew.com
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </>
  );
};

export default BlockedAccount;
